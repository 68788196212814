import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import { withTimeout } from 'utils/utils'
import { useWebSocket } from '../../utils/WebSocketProvider'
import GameoverView from './GameoverView'
import GameView from './GameView'

const PlayContainer = () => {
  const socket = useWebSocket()
  const gameover = useAppSelector(state => state.game.gameover)
  const navigate = useNavigate()

  useEffect(() => {
    // check if we have all the items, otherwise go back to lobby
    socket?.emit('in_active_game', (in_active_game: boolean) => {
      if (!in_active_game) {
        navigate('/game_selection')
      } else {
        socket?.emit('get_whole_game', withTimeout(() => { }, () => { toast.error(`Request timed out! There might be an server-side error. Please contact the developer and refresh.`) }, 5000))
      }
    })
  }, [socket])

  return (
    <div>
      {gameover ? <GameoverView /> : <GameView />}
    </div>
  )
}

export default React.memo(PlayContainer)
