import {
  Box,
  Button,
  Paper,
  Typography,
  Grid,
  Divider,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import LinearProgressWithLabel from "components/LinearProgressWithLabel/LinearProgressWithLabel";
import React, {
  LegacyRef,
  MutableRefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "store/hooks";
import api from "utils/api";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import Statistics from "./Statistics";
import Achievements from "./Achievements";
import Avatar from "avataaars";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { setUserInfo, UserInfo } from "store/slices/userInfoSlice";
import { Dispatch } from "@reduxjs/toolkit";
import { setErrorMessage } from "store/slices/errorMessageSlice";
import { sendingRequest } from "store/slices/sendingRequestSlice";
import { getAccessToken, loadMe } from "utils/auth";
import { getCurrency } from "components/Lobby/PlayerData";

export const getUserProfile = (me: string) => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true));
    dispatch(setErrorMessage(""));
    api
      .get(`/api/user/${me}`, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((data) => {
        const userInfo: UserInfo = {
          username: data.data.username,
          level: data.data.level,
          totalScore: data.data.totalScore,
          currentLevelBaseScore: data.data.currentLevelBaseScore,
          scoreToNextLevel: data.data.scoreToNextLevel,
        };

        dispatch(setUserInfo(userInfo));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText));
        }
      })
      .finally(() => {
        dispatch(sendingRequest(false));
      });
  };
};

export default function UserProfile() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const targetRef: MutableRefObject<any> = useRef();
  // const size = useDimensions(targetRef);

  const me = useAppSelector((state) => state.me);

  const userInfo = useAppSelector((state) => state.userInfo);
  const [progress, setProgress] = useState(0);

  const redirectToHome = () => {
    navigate("/");
  };

  const [editProfilePicture, setEditProfilePicture] = React.useState(false);

  const handleClickOpen = () => {
    setEditProfilePicture(true);
  };

  const handleClose = () => {
    setEditProfilePicture(false);
  };

  useEffect(() => {
    dispatch(loadMe());
  }, []);

  useEffect(() => {
    if (me !== "") {
      dispatch(getUserProfile(me));
    }
  }, [me]);

  const currency = useAppSelector((state) => state.currency);

  useEffect(() => {
    setProgress(
      Math.round(
        ((userInfo.totalScore - userInfo.currentLevelBaseScore) /
          userInfo.scoreToNextLevel) *
          100
      ) / 100
    );
  }, [userInfo]);

  // function useDimensions(targetRef: any) {
  //   const getDimensions = () => {
  //     return {
  //       width: targetRef.current ? targetRef.current.offsetWidth : 0,
  //       height: targetRef.current ? targetRef.current.offsetHeight : 0,
  //     };
  //   };

  //   const [dimensions, setDimensions] = useState(getDimensions);

  //   const handleResize = () => {
  //     setDimensions(getDimensions());
  //   };

  //   useEffect(() => {
  //     window.addEventListener("resize", handleResize);
  //     return () => window.removeEventListener("resize", handleResize);
  //   }, []);

  //   useLayoutEffect(() => {
  //     handleResize();
  //   }, []);
  //   return dimensions;
  // }

  useEffect(() => {
    dispatch(getCurrency());
  }, []);

  return (
    <div className="MainContainer">
      <div className="Profile_content">
        <Paper>
          <Box className="h-fit p-10" sx={{ flexGrow: 1 }}>
            <Typography sx={{ flex: "1 1 100%" }} variant="h4" className="py-5">
              Profile
            </Typography>
            <Container className="flex w-screen" maxWidth={false}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                className="h-15 w-20 align-middle"
              >
                <Grid
                  xs={1}
                  sm={1}
                  md={1}
                  item
                  className="h-15 w-20 self-center align-middle"
                >
                  <Box className="relative">
                    {/* <ModeEditIcon
                      onClick={handleClickOpen}
                      className="cursor-pointer	absolute z-10 top-1 -right-10"
                      // style={{ right: `${size.width / 4}px` }}
                    /> */}
                    <Avatar
                      // ref={targetRef}
                      avatarStyle="Transparent"
                      topType="LongHairStraight"
                      accessoriesType="Kurt"
                      hairColor="PastelPink"
                      facialHairType="Blank"
                      clotheType="BlazerShirt"
                      eyeType="Default"
                      eyebrowType="Default"
                      mouthType="Default"
                      skinColor="Light"
                      className="h-32 w-32 w-screen border-4 border-black bg-accentYellow self-center"
                    />
                  </Box>
                </Grid>
                <Grid xs={3} item className="self-center">
                  <Typography className="py-3" variant="h4">
                    {userInfo.username}
                  </Typography>
                  <Typography variant="h5">
                    <SignalCellularAltIcon
                      fontSize="inherit"
                      sx={{ mb: 0.5 }}
                    />{" "}
                    Lvl. {userInfo.level}
                  </Typography>
                </Grid>
                <Grid xs={3} item className="self-center align-middle">
                  <Typography className="pt-5" variant="h5">
                    Total score: {userInfo.totalScore}
                  </Typography>
                  <LinearProgressWithLabel value={progress} />
                  <Typography variant="subtitle1">
                    <span className="text-primary">
                      {userInfo.totalScore - userInfo.currentLevelBaseScore}/
                    </span>
                    {userInfo.scoreToNextLevel} to level {userInfo.level + 1}
                  </Typography>
                </Grid>
                <Grid xs={2} item className="self-center align-middle">
                  <Typography className="pt-5" variant="h5">
                    <MonetizationOnIcon sx={{ mb: 0.5 }} /> Currency: {currency}
                  </Typography>
                </Grid>
              </Grid>
            </Container>
            {/*<Statistics />*/}
            {/*<Achievements />*/}
          </Box>
        </Paper>
        <div className="py-4"></div>
        <Box>
          <a
            className="AuthButton btn btn-primary"
            style={{ padding: "1em" }}
            onClick={redirectToHome}
          >
            Back to main menu
          </a>
        </Box>
        {/* <Dialog open={editProfilePicture}>
          <DialogTitle>Change profile picture</DialogTitle>
          <DialogContent>TBD</DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Back</Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              form="difficultyForm"
              id="difficultySubmit"
              disableElevation
              onClick={handleClose}
              style={{ gridArea: "confirm", width: "30%" }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    </div>
  );
}
