import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from "@mui/material";
import BackButton from "components/Game/ActionBar/BackButton";
import FreeTextDialog from "components/Game/ActionBar/FreeTextDialog";
import SpellCheck from "components/Game/ActionBar/SpellCheck";
import { MAX_WORDS, MAX_FREE_WORDS } from "constants/AppConstants";
import React, { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { CustomDialog } from "react-st-modal";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Action, setAskerAction } from "store/slices/askerActionSlice";
import { setGuessCard } from "store/slices/guessCardSlice";
import { setTargetInput } from "store/slices/targetInputSlice";
import { setTargetRelation } from "store/slices/targetRelationSlice";
import { Typo } from "typo-js-ts";
import { useWebSocket } from "utils/WebSocketProvider";
import { getInventory } from "components/Lobby/PlayerData";

export interface Mistake {
  word: string;
  recommendations: string[];
}

const AskBar = () => {
  const relations = useAppSelector((state) => state.game.relations);
  const targetIndex = useAppSelector((state) => state.game.target.index);
  const targetValue = useAppSelector((state) => state.game.target.value);
  const cards = useAppSelector((state) => state.game.cards);
  const dispatch = useAppDispatch();
  var dict = new Typo("en_US", undefined, undefined, {
    dictionaryPath: `${window.location.protocol}//${window.location.host}/public/typo/dictionaries`,
    // TODO check if any flags are needed
    flags: {},
  });
  const tabooWords = cards.map((card) => card["id"]);

  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const socket = useWebSocket();

  const inventory = useAppSelector((state) => state.inventory);
  const [freeTextQuantity, setFreeTextQuantity] = useState(0);

  useEffect(() => {
    dispatch(getInventory());
  }, []);

  useEffect(() => {
    setFreeTextQuantity(inventory["freeTextHint"] ?? 0);
  }, [inventory]);

  const relationChoices = relations.map((relation, index) =>
    relation.name === "FreeText" && freeTextQuantity === 0 ? (
      <Tooltip title={`You don't have enough hints`} placement="bottom" arrow>
        <div>
          <MenuItem key={relation.name} value={index} dense disabled>
            {relation.name}
          </MenuItem>
        </div>
      </Tooltip>
    ) : (
      <MenuItem key={relation.name} value={index} dense>
        {relation.name}
      </MenuItem>
    )
  );

  const handleRelation = (
    event: SelectChangeEvent<typeof targetIndex | -1>
  ) => {
    const value = event.target.value;
    const relationIndex =
      typeof value === "string" ? Number.parseInt(value) : value;
    dispatch(setTargetRelation(relationIndex));
  };

  const validate = (name: string, text: string) => {
    const validChars = /^[0-9a-zA-Z '-/]*$/;
    // check if have illegal characters
    if (!validChars.test(text)) {
      setErrorMsg("Alphanumeric characters only");
      return false;
    }
    // check if have more than MAX_WORDS words
    let words = text.split(" ").filter(Boolean);

    if (name === "FreeText" && words.length > MAX_FREE_WORDS) {
      setErrorMsg(`Max ${MAX_FREE_WORDS} words`);
      return false;
    } else if (name !== "FreeText" && words.length > MAX_WORDS) {
      setErrorMsg(`Max ${MAX_WORDS} words`);
      return false;
    }

    // TODO split double words

    let taboo = words.some((word) =>
      tabooWords.some((tWord) =>
        word.toLowerCase().includes(tWord.toLowerCase())
      )
    );
    if (taboo) {
      setErrorMsg(`Card words are not allowed`);
      return false;
    }

    // TODO check free words

    return true;
  };

  const spellCheck = (text: string) => {
    return dict.ready
      .then(async () => {
        const tokens = text.split(/[ '-/\(\)]+/).filter(Boolean);
        var mistakes: Mistake[] = [];
        for (const token of tokens) {
          if (!dict.check(token)) {
            mistakes.push({
              word: token,
              recommendations: dict.suggest(token, 3),
            });
          }
        }

        if (mistakes.length === 0) {
          return Promise.resolve(true);
        }
        return Promise.resolve(await showSpellCheckDialog(mistakes));
      })
      .catch((error) => {
        console.log(error);
        // if dictionary is not yet loaded, then proceed without checking
        return Promise.resolve(true);
      });
  };

  const showSpellCheckDialog = async (mistakes: Mistake[]) => {
    const result = await CustomDialog(<SpellCheck mistakes={mistakes} />, {
      title: "Spelling mistakes found",
      showCloseIcon: true,
    });
    return Promise.resolve(result);
  };

  const showFreeTextDialog = async () => {
    const result = await CustomDialog(
      <FreeTextDialog hints={freeTextQuantity} />,
      {
        title: "Free Text question",
        showCloseIcon: true,
      }
    );
    return Promise.resolve(result);
  };

  const handleTarget = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTargetInput(value));
    if (validate(relations[targetIndex].name, value)) {
      setError(false);
      setErrorMsg("");
    } else {
      setError(true);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (targetIndex === -1) {
      setErrorMsg("Question type not selected");
      return false;
    } else if (targetValue.length === 0) {
      setErrorMsg("Cannot be empty");
      return false;
    }
    if (validate(relations[targetIndex].name, targetValue)) {
      let result;
      if (relations[targetIndex].name === "FreeText") {
        result = await showFreeTextDialog();
      }

      result = await spellCheck(targetValue);
      // TODO possible bug here where the window does not show up. Maybe skip if undefined
      if (result) {
        socket?.emit(
          "send_question",
          {
            question_type: relations[targetIndex].name,
            target: targetValue,
          },
          ({ ack, msg }: { ack: boolean; msg: string }) => {
            if (ack) {
              dispatch(setTargetInput(""));
              dispatch(setGuessCard(-1));
              dispatch(setTargetRelation(-1));
              dispatch(setAskerAction(Action.NONE));

              if (relations[targetIndex].name === "FreeText") {
                // Update inventory
                dispatch(getInventory());
              }

              toast.success(msg);
            } else {
              toast.error(msg, { id: "error" });
            }
          }
        );
      }
    }
  };

  const getQuestion = (index: number) => {
    if (index === -1) {
      return "Please choose a question type...";
    }
    return relations[index].question;
  };

  return relations === undefined ||
    relations === null ||
    relations.length === 0 ? (
    <>'Loading...'</>
  ) : (
    <Fragment>
      <form noValidate autoComplete="off" id="askForm" onSubmit={handleSubmit}>
        {/* TODO fix strange label color */}
        <FormControl>
          <InputLabel id="relation-label" color="secondary">
            Question type
          </InputLabel>
          <Select
            margin="dense"
            id="relation"
            labelId="relation-label"
            label="Question type"
            color="secondary"
            value={targetIndex}
            onChange={handleRelation}
            variant="outlined"
            size="small"
            style={{ minWidth: "14ch" }}
          >
            {relationChoices}
          </Select>
        </FormControl>

        <div id="targetQuestion">
          <span className={targetIndex === -1 ? "text-disabled mt-4" : ""}>
            {getQuestion(targetIndex)}
          </span>
          {targetIndex !== -1 ? (
            <Fragment>
              <FormControl>
                <InputLabel
                  htmlFor="component-simple"
                  margin="dense"
                  shrink
                  color="secondary"
                >
                  {relations[targetIndex].name === "FreeText"
                    ? `Max ${MAX_FREE_WORDS} words`
                    : `Max ${MAX_WORDS} words`}
                </InputLabel>
                <Input
                  error={error}
                  id="target-input"
                  value={targetValue}
                  onChange={handleTarget}
                  placeholder=""
                  aria-describedby="component-error-text"
                  margin="dense"
                  autoCapitalize="none"
                  color="secondary"
                  required
                />
                <FormHelperText
                  id="component-error-text"
                  error
                  color="secondary"
                >
                  {errorMsg}
                </FormHelperText>
              </FormControl>
              <span>?</span>
            </Fragment>
          ) : (
            ""
          )}
        </div>

        {targetIndex !== -1 ? (
          <Button
            color="secondary"
            variant="contained"
            onClick={handleSubmit}
            type="submit" //set the button type is submit
            form="askForm" //target the form which you want to sent
            id="targetSubmit"
            disableElevation
          >
            SEND
          </Button>
        ) : (
          ""
        )}
      </form>
      <BackButton />
    </Fragment>
  );
};

export default React.memo(AskBar);
