import AskBar from "components/Game/ActionBar/AskBar";
import ChoiceBar from "components/Game/ActionBar/ChoiceBar";
import FlipBar from "components/Game/ActionBar/FlipBar";
import GuessBar from "components/Game/ActionBar/GuessBar";
import ReplyBar from "components/Game/ActionBar/ReplyBar";
import { useAppSelector } from "store/hooks";
import {
  ACTION_ASK,
  ACTION_GUESS,
  MIN_QUESTIONS,
  STAGE_ASKER_ASK_GUESS,
  STAGE_ASKER_FLIP,
  STAGE_BONUS,
  STAGE_ENDGAME,
  STAGE_REPLIER_REPLY,
} from "../../../constants/AppConstants";

export default function ActionBar() {
  const action = useAppSelector((state) => state.game.action);
  const myTurn = useAppSelector((state) => state.game.myTurn);
  const stage = useAppSelector((state) => state.game.stage);
  const questionHistory = useAppSelector((state) => state.game.questionHistory);
  const difficulty = useAppSelector((state) => state.gameDifficulty);
  const isGuessDisabled =
    stage !== STAGE_BONUS &&
    questionHistory.length + (stage === STAGE_ASKER_FLIP ? 1 : 0) <
      MIN_QUESTIONS[difficulty];

  const renderMessage = () => {
    switch (stage) {
      case STAGE_ASKER_ASK_GUESS:
        if (myTurn) {
          switch (action) {
            case ACTION_ASK:
              return <AskBar />;
            case ACTION_GUESS:
              return <GuessBar />;
            default:
              return <ChoiceBar isGuessDisabled={isGuessDisabled} />;
          }
        } else {
          return (
            <div className="MessageText">
              The opponent is choosing an action
              <div className="dot-flashing"></div>
            </div>
          );
        }

      case STAGE_REPLIER_REPLY:
        if (myTurn) {
          return <ReplyBar />;
        } else {
          return (
            <div className="MessageText">
              The opponent is replying<div className="dot-flashing"></div>
            </div>
          );
        }

      case STAGE_ASKER_FLIP:
        if (myTurn) {
          switch (action) {
            case ACTION_GUESS:
              return <GuessBar />;
            default:
              return <FlipBar isGuessDisabled={isGuessDisabled} />;
          }
        } else {
          return (
            <div className="MessageText">
              The opponent is turning cards<div className="dot-flashing"></div>
            </div>
          );
        }

      case STAGE_BONUS:
        return <GuessBar />;

      case STAGE_ENDGAME:
        // should not happen
        return "Game over";
    }
  };

  return <div className="ActionPanel">{renderMessage()}</div>;
}
