import { Button, Modal } from "@mui/material"
import popSound from "audio/pop.wav"
import ActionBar from "components/Game/ActionBar/ActionBar"
import Board from "components/Game/Board/Board"
import StatusBar from "components/Game/StatusBar/StatusBar"
import TopBar from "./TopBar"
import Tutorial from "components/Game/Tutorial/Tutorial"
import {
  AUDIO_ENABLED,
  COOKIE_DISCLAIMER,
  COOKIE_DISCLAIMER_HIDE,
  COOKIE_DISCLAIMER_SHOW,
} from "constants/AppConstants"
import { useCallback, useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { Confirm, CustomDialog, StaticDialog } from "react-st-modal"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { setInstructionShown } from "store/slices/instructionShownSlice"
import { setTextMode } from "store/slices/textModeSlice"
import { useEventListener } from "usehooks-ts"
import useCustomSound from "utils/useCustomSound"
import { useWebSocket } from "utils/WebSocketProvider"
import { sendingRequest } from "store/slices/sendingRequestSlice"
import { setErrorMessage } from "store/slices/errorMessageSlice"
import { Dispatch } from "@reduxjs/toolkit"
import api from "utils/api"
import { getAccessToken } from "utils/auth"
import { setCurrency } from "store/slices/currencySlice"
import { getInventory } from "components/Lobby/PlayerData"

export default function GameView() {
  const dispatch = useAppDispatch()
  const cards = useAppSelector((state) => state.game.cards)
  const textMode = useAppSelector((state) => state.game.textMode)
  const questionHistory = useAppSelector((state) => state.game.questionHistory)
  const instructionsShown = useAppSelector((state) => state.instructionsShown)

  const inventory = useAppSelector((state) => state.inventory)
  const [cardFlipQuantity, setCardFlipQuantity] = useState(0)
  const [freeTextQuantity, setFreeTextQuantity] = useState(0)

  const socket = useWebSocket()
  const [menuOpen, setMenuOpen] = useState(false)
  const [historyOpen, setHistoryOpen] = useState(false)
  const [cookies, setCookie] = useCookies([COOKIE_DISCLAIMER, AUDIO_ENABLED])
  const playPop = useCustomSound(popSound, 0.5)

  const showTutorial = async () => {
    const result = await CustomDialog(
      // <></>,
      <Tutorial
        original={cookies[COOKIE_DISCLAIMER] === COOKIE_DISCLAIMER_HIDE}
      />,
      {
        title: "Tutorial",
        showCloseIcon: true,
      }
    )

    const res =
      result ||
      (cookies[COOKIE_DISCLAIMER] === "undefined"
        ? false
        : cookies[COOKIE_DISCLAIMER]) ||
      COOKIE_DISCLAIMER_SHOW

    if (result !== "undefined") {
      dispatch(setInstructionShown(false))
    }

    // hide for a month
    setCookie(COOKIE_DISCLAIMER, res, { path: "/", maxAge: 30 * 86400 })
  }

  const handleOpen = () => {
    setMenuOpen(true)
  }

  const handleClose = () => {
    setMenuOpen(false)
  }

  const openInstructions = () => {
    setMenuOpen(false)
    if (!instructionsShown) {
      dispatch(setInstructionShown(false))
      showTutorial()
    }
  }

  const openHistory = () => {
    setMenuOpen(false)
    setHistoryOpen(true)
  }

  const toggleTextMode = () => {
    dispatch(setTextMode(!textMode))
  }

  const toggleSound = () => {
    if (cookies[AUDIO_ENABLED] !== "true") {
      playPop({ forceSoundEnabled: true })
    }
    setCookie(AUDIO_ENABLED, !(cookies[AUDIO_ENABLED] === "true"), {
      path: "/",
      maxAge: 30 * 86400,
    })
  }

  useEffect(() => {
    dispatch(getInventory())
  }, [])

  useEffect(() => {
    setCardFlipQuantity(inventory["flipCardHint"] ?? 0)
    setFreeTextQuantity(inventory["freeTextHint"] ?? 0)
  }, [inventory])

  const endGame = async () => {
    setMenuOpen(false)
    if (
      await Confirm(
        "Are you sure you want to forfeit the game? You will automatically lose and the task will not be count as completed.",
        "Forfeit game?",
        "End game",
        "Cancel"
      )
    ) {
      socket?.emit("forfeit_game")
    }
  }

  const handler = useCallback(
    (event) => {
      switch (event.keyCode) {
        case 27:
          if (historyOpen) {
            setHistoryOpen(false)
          } else {
            handleOpen()
          }
          break
      }
    },
    [setMenuOpen, historyOpen, textMode]
  )

  useEventListener("keydown", handler)

  useEffect(() => {
    if (
      !instructionsShown &&
      cookies[COOKIE_DISCLAIMER] !== COOKIE_DISCLAIMER_HIDE
    ) {
      dispatch(setInstructionShown(false))
      showTutorial()
    }
  }, [])

  return (
    <div>
      <StaticDialog
        isOpen={historyOpen}
        title="Question History"
        onAfterClose={() => {
          setHistoryOpen(false)
        }}
        showCloseIcon
      >
        <div className="QuestionHistory" style={{ minHeight: "30vh" }}>
          {questionHistory.length === 0
            ? "No questions asked yet"
            : questionHistory.map((turn, index) => {
              return (
                <div className="QuestionHistory-Items" key={`item-${index}`}>
                  <span
                    key={`question-${index}`}
                    className="QuestionHistory-Items-Question"
                  >
                    {turn["question"]}
                  </span>
                  <span
                    key={`reply-${index}`}
                    className="QuestionHistory-Items-Reply"
                    title={turn["reply"]}
                  >
                    {turn["reply"]}
                  </span>
                </div>
              )
            })}
        </div>
      </StaticDialog>

      <Modal open={menuOpen} onClose={handleClose}>
        <div
          className="Modal"
          style={{ width: "500px", paddingTop: "48px", paddingBottom: "40px" }}
        >
          <h1 className="mb-12 text-bold">MENU</h1>

          <Button
            variant="text"
            color="inherit"
            className="mb-8 MenuButton text-large"
            size="large"
            onClick={handleClose}
            disableElevation
          >
            Continue
          </Button>

          <Button
            variant="text"
            color="inherit"
            className="mb-8 MenuButton text-large"
            size="large"
            onClick={openInstructions}
            disableElevation
          >
            Instructions
          </Button>

          <Button
            variant="text"
            color="inherit"
            className="mb-8 MenuButton text-large"
            size="large"
            onClick={openHistory}
            disableElevation
          >
            Question history
          </Button>

          <Button
            variant="text"
            color="inherit"
            className="mb-8 MenuButton text-large"
            size="large"
            onClick={toggleTextMode}
            disableElevation
          >
            Enable {textMode ? "image" : "text"} mode
          </Button>

          {/* <Button
            label="Enable audio"
            variant="text"
            color="inherit"
            className="mb-8 MenuButton text-large"
            size="large"
            onClick={toggleSound}
            disableElevation
            startIcon={cookies[AUDIO_ENABLED] === 'true' ? <VolumeUp /> : <VolumeOff />}
          >{cookies[AUDIO_ENABLED] === 'true' ? 'Disable' : 'Enable'} sound</Button> */}

          <Button
            variant="text"
            color="inherit"
            className="MenuButton text-large btn-alert"
            size="large"
            onClick={endGame}
            disableElevation
          >
            Forfeit game
          </Button>
        </div>
      </Modal>

      {cards === undefined || cards === null || cards.length === 0 ? (
        "Loading..."
      ) : (
        <div className="GameWrapper">
          <TopBar />
          <StatusBar />
          <div>
            <Board objects={cards} />
          </div>
          <ActionBar />
          <span id="BottomMessage" className="text-left">
            <b>Free text hints: </b>x{freeTextQuantity} <br />
            <b>Card flip hints: </b>x{cardFlipQuantity}
          </span>
          <span id="BottomMessage" className="text-center text-disabled">
            You can press ESC to access the menu during the game. Refresh the
            page if nothing happens for longer than a couple minutes.
          </span>
        </div>
      )}
    </div>
  )
}
