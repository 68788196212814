import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { ACCESS_TOKEN_NAME, SERVER_URL } from '../constants/AppConstants'
import { AuthenticationPayload, getAccessToken, getRefreshToken, setAccessToken } from './auth'

axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/json'

const api = axios.create({
  baseURL: SERVER_URL,
  responseType: 'json',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
})


// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: any) =>
  api.post<AuthenticationPayload>('/api/refresh', { headers: { 'Authorization': `Bearer ${getRefreshToken()}` }})
    .then(response => {
      setAccessToken(response.data.data.payload.token)
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + getAccessToken()
      return Promise.resolve()
    })
// .catch(error => {
//   if (error.response) {
//     dispatch(setErrorMessage(error.response.statusText))
//   }
// })
// const refreshAuthLogic = (failedRequest:any) => {
//   api.post<AuthenticationPayload>('/api/refresh', { headers: { 'Authorization': `Bearer ${getRefreshToken()}` } })
//     .then(tokenRefreshResponse => {
//       localStorage.setItem(ACCESS_TOKEN_NAME, tokenRefreshResponse.data.data.payload.token)
//       failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.data.payload.token
//       return Promise.resolve()
//     })
//     // .catch(error => {
//     //   if (error.response) {
//     //     dispatch(setErrorMessage(error.response.statusText))
//     //   }
//     // })
// }

createAuthRefreshInterceptor(api, refreshAuthLogic)

export default api