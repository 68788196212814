import { Dispatch } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'
import { createAuthProvider } from 'react-token-auth'
import { setCompleted } from 'store/slices/completedSlice'
import { setErrorMessage } from 'store/slices/errorMessageSlice'
import { setMe } from 'store/slices/meSlice'
import { setOnlineCount } from 'store/slices/onlineCountSlice'
import { setProgress } from 'store/slices/progressSlice'
import { sendingRequest } from 'store/slices/sendingRequestSlice'
import api from 'utils/api'
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../constants/AppConstants'


export interface Session {
  access_token: string
  refresh_token?: string
}

// Probably needs to be fixed
export interface AuthenticationPayload {
  status: string,
  data: {
    user: {
      id: string
      username: string
    }
    payload: {
      type: string
      token: string
      refresh_token?: string
    }
  },
}
export const [useAuth, authFetch, authLogin, authLogout] = createAuthProvider<Session>({
  accessTokenKey: 'access_token',
  storage: localStorage,
  onUpdateToken: token =>
    api.post<AuthenticationPayload>('/auth/refresh', {
      method: 'POST',
      body: {
        refreshToken: token.refresh_token
      }
    }).then(r => {
      return getTokensAsSession(r.data)
    })
      .finally(() => {
        localStorage.removeItem('REACT_TOKEN_AUTH_KEY')
      })
})

// export const [useAuth, authFetch, authLogin, authLogout] = createAuthProvider({
//   accessTokenKey: 'access_token',
//   onUpdateToken: (token) => {
//     refresh_token = token.refresh_token
//     api.post('/api/refresh', {
//       method: 'POST',
//       headers: {
//         'Authorization': `Bearer ${refresh_token}`
//       }
//     })
//       .then(r => r.json())
//       .finally(() => {
//         localStorage.removeItem('REACT_TOKEN_AUTH_KEY')
//       })
//   }
// })

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_NAME)
}

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_NAME)
}

export const setAccessToken = (access_token: string) => {
  localStorage.setItem(ACCESS_TOKEN_NAME, access_token)
}

export const setRefreshToken = (refresh_token: string) => {
  localStorage.setItem(REFRESH_TOKEN_NAME, refresh_token)
}

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_NAME)
}

export const removeRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_NAME)
}

const getTokensAsSession = (payload: AuthenticationPayload): Session => {
  return {
    "access_token": payload.data.payload.token,
    "refresh_token": payload.data.payload.refresh_token
  }
}


// --------------- AXIOS

export const login = (username: string, password: string) => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true))
    dispatch(setErrorMessage(''))
    api.post(`/api/login`, { username, password })
      .then(data => {
        authLogin(data.data)
        setAccessToken(data.data.access_token)
        setRefreshToken(data.data.refresh_token)
        // dispatch(setAuthState(data.data.isLoggedIn))
      })
      .catch(error => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText))
        }
      })
      .finally(() => {
        dispatch(sendingRequest(false))
      })
  }
}

export const register = (username: string, password: string, pid: string | undefined = undefined) => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true))
    dispatch(setErrorMessage(''))
    api.post(`/api/register`, pid ? { username, password, pid } : { username, password })
      .then(data => {
        authLogin(data.data)
        setAccessToken(data.data.access_token)
        setRefreshToken(data.data.refresh_token)
        // dispatch(setAuthState(data.data.isLoggedIn))
      })
      .catch(error => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText))
        }
      })
      .finally(() => {
        dispatch(sendingRequest(false))
      })
  }
}

export const loadMe = () => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true))
    dispatch(setErrorMessage(''))
    api.get(`/api/me`, {
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    })
      .then(data => {
        // No problem, token still valid
        dispatch(setMe(data.data.username))
      })
      .catch(error => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText))
        }
        toast.error('Login session expired. Please log in again.', { id: 'credentials', duration: 5000 })
        authLogout()
        removeAccessToken()
        removeRefreshToken()
      })
      .finally(() => {
        dispatch(sendingRequest(false))
      })
  }
}

export const loadOnlinePlayers = () => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true))
    dispatch(setErrorMessage(''))
    api.get(`/api/players_online`)
      .then(data => {
        dispatch(setOnlineCount(data.data))
      })
      .catch(error => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText))
        }
      })
      .finally(() => {
        dispatch(sendingRequest(false))
      })
  }
}

export const refreshProgress = () => {
  return (dispatch: Dispatch) => {
    api.get('/api/progress', {
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      },
      timeout: 5000
    }).then(data => {
      const p = data.data.progress
      const completed = data.data.completed ? true : false
      if (p !== undefined && p !== null) {
        dispatch(setProgress(p))
        dispatch(setCompleted(completed))
      } else {
        // TODO else condition for when the server could not find user / not is a user without prolific id.
      }
    })
  }
}

export const logout = () => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true))
    dispatch(setErrorMessage(''))
    api.get('/api/logout', {
      headers: {
        'Authorization': `Bearer ${getAccessToken()}`
      }
    })
      .then(data => {
        authLogout()
        removeAccessToken()
        removeRefreshToken()
        localStorage.clear()
      })
      .catch(error => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText))
        }
      })
      .finally(() => {
        dispatch(sendingRequest(false))
      })
  }
}