import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { insertIntoArray } from 'utils/insertIntoArray'

export interface Card {
  id: string
  weight: number
  image_url: string
  definitions: string[]
  is_flipped: boolean
}

const initialState: Card[] = []

const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    setCards: (state, action: PayloadAction<(Card[])>) => {
      return state = action.payload
    },
    flipCard: (state, action: PayloadAction<number>) => {
      const cardIdx = action.payload
      return state = insertIntoArray(state, cardIdx, { ...state[cardIdx], is_flipped: (!state[cardIdx].is_flipped) })
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCards, flipCard } = cardsSlice.actions

export default cardsSlice.reducer