import ErrorView from 'components/ErrorView'
import LoadingView from 'components/LoadingView'
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { logout, useAuth } from 'utils/auth'


export default function LogoutContainer() {
  const dispatch = useAppDispatch()
  const [logged] = useAuth()
  const currentlySending = useAppSelector(state => state.currentlySending)
  const errorMessage = useAppSelector(state => state.errorMessage)

  useEffect(() => {
    dispatch(logout())
  }, [])

  return (
    <div>
      {!logged && <Navigate to="/login" />}
      <LoadingView currentlySending={currentlySending} />
      <ErrorView message={errorMessage} />
    </div>
  )

}
