import { Box, CircularProgress, Typography } from "@mui/material"
import { useLocation } from "react-router"
import { Card } from "store/slices/cardsSlice"
import { Progress } from "store/slices/progressSlice"
import { NUM_TASKS } from "constants/AppConstants"

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const removeUnderscore = (word: string) => {
  return capitalize(word.replaceAll('_', ' '))
}

export const withTimeout = (onSuccess: Function, onTimeout: Function, timeout: any) => {
  let called = false

  const timer = setTimeout(() => {
    if (called) return
    called = true
    onTimeout()
  }, timeout)

  return (...args: any) => {
    if (called) return
    called = true
    clearTimeout(timer)
    onSuccess.apply(this, args)
  }
}

export const simplifyBoard = (cards: Card[]) => {
  return cards.map(card => { return { 'id': card['id'], 'is_flipped': card['is_flipped'] } })
}


// export const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? wrapper(children) : children


export const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const clamp = function (num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max)
}

export const randomInt = function (min: number, max: number) {
  return Math.floor(Math.random() * (max - min)) + min
}

export const reduceCards = (cards: Card[]) => {
  return cards.reduce((total, card) => { return (total << 1) | (card.is_flipped ? 1 : 0) }, 0)
}

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]
  }
  return array
}

const getProgressCompletion = (progress: Progress): Progress => {
  return {
    'EASY': clamp(progress.EASY, 0, 3),
    'MEDIUM': clamp(progress.MEDIUM, 0, 2),
    'HARD': clamp(progress.HARD, 0, 0)
  }
}

const isObjectEmpty = (obj: object) => {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object
}

export const getRemainingTasks = (progress: Progress): [number, Progress] => {
  if (isObjectEmpty(progress)) {
    return [-1, { EASY: 0, MEDIUM: 0, HARD: 0 }]
  }
  const taskCompletion = getProgressCompletion(progress)
  return [NUM_TASKS - Object.values(taskCompletion).reduce((a, b) => a + b), taskCompletion]
}

export function CircularProgressWithLabel({ value, ...props }: { value: number }) {
  // return value === 100 ? <CheckCircle color="secondary" /> :
  return (<Box position="relative" display="inline-flex" {...props}>
    <div>
      <CircularProgress variant="determinate" value={value} size="4em" />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="subtitle1" component="div" color="textSecondary" > {`${Math.round(value)}%`
        } </Typography>
      </Box>
    </div>
  </Box>)
}