import { Button, ButtonGroup } from '@mui/material'
import { ANSWER_MAYBE, ANSWER_NO, ANSWER_UNCLEAR, ANSWER_YES } from 'constants/AppConstants'
import { Fragment } from "react"
import toast from 'react-hot-toast'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { useWebSocket } from 'utils/WebSocketProvider'
import { setAnswer } from 'store/slices/answerSlice'


export default function ReplyBar() {
  const answer = useAppSelector(state => state.game.answer)
  const question = useAppSelector(state => state.game.question)

  const socket = useWebSocket()
  const dispatch = useAppDispatch()

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (answer === '') {
      toast.error('Please select an answer.')
      return false
    }

    socket?.emit('reply_question', answer, ({ ack, msg }: { ack: boolean, msg: string }) => {
      if (ack) {
        toast.success(msg)
      } else {
        toast.error(msg, { id: 'error' })
      }
    })

    dispatch(setAnswer(''))
  }

  const handleUnclear = () => {
    socket?.emit('reply_question', ANSWER_UNCLEAR, ({ ack, msg }: { ack: boolean, msg: string }) => {
      if (ack) {
        toast.success(msg)
      } else {
        toast.error(msg, { id: 'error' })
      }
    })
  }

  const setSelectedBtn = (answer: string) => {
    dispatch(setAnswer(answer))
  }

  return (
    <Fragment>
      <form noValidate autoComplete="off" id="answerForm" onSubmit={handleSubmit}>
        <span className="text-larger">{question}</span>

        <ButtonGroup
          size="medium"
          color="secondary"
          disableElevation
        >
          <Button key="yes" onClick={() => setSelectedBtn(ANSWER_YES)} variant={answer === ANSWER_YES ? "contained" : "outlined"}>YES</Button>
          <Button key="no" onClick={() => setSelectedBtn(ANSWER_NO)} variant={answer === ANSWER_NO ? "contained" : "outlined"}>NO</Button>
          <Button key="maybe" onClick={() => setSelectedBtn(ANSWER_MAYBE)} variant={answer === ANSWER_MAYBE ? "contained" : "outlined"}>MAYBE</Button>
        </ButtonGroup>

        <Button
          color="secondary"
          variant="contained"
          type="submit"
          form="answerForm"
          id="answerSubmit"
          disableElevation
        >SEND</Button>
      </form >
      <Button
        color="secondary"
        variant="contained"
        size="large"
        className="BigButton BackButton"
        onClick={handleUnclear}
        disableElevation
      >UNCLEAR</Button>
    </Fragment>
  )

}