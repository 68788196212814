import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authFormSlice from "store/slices/authFormSlice";
import errorMessageSlice from "store/slices/errorMessageSlice";
import sendingRequestSlice from "store/slices/sendingRequestSlice";
import loadingSlice from "store/slices/loadingSlice";
import dataSlice from "store/slices/dataSlice";
import freshLoginSlice from "store/slices/freshLoginSlice";
import onlineCountSlice from "store/slices/onlineCountSlice";
import progressSlice from "store/slices/progressSlice";
import completedSlice from "store/slices/completedSlice";
import sessionSlice from "store/slices/sessionSlice";
import difficultySlice from "store/slices/difficultySlice";
import instructionShownSlice from "store/slices/instructionShownSlice";
import myTurnSlice from "store/slices/myTurnSlice";
import roleSlice from "store/slices/roleSlice";
import itCardSlice from "store/slices/itCardSlice";
import opponentItCardSlice from "store/slices/opponentItCardSlice";
import meSlice from "store/slices/meSlice";
import opponentSlice from "store/slices/opponentSlice";
import relationSlice from "store/slices/relationSlice";
import targetRelationSlice from "store/slices/targetRelationSlice";
import targetInputSlice from "store/slices/targetInputSlice";
import askerActionSlice from "store/slices/askerActionSlice";
import guessCardSlice from "store/slices/guessCardSlice";
import stageSlice from "store/slices/stageSlice";
import questionSlice from "store/slices/questionSlice";
import answerSlice from "store/slices/answerSlice";
import textModeSlice from "store/slices/textModeSlice";
import forfeitedSlice from "store/slices/forfeitedSlice";
import gameoverSlice from "store/slices/gameoverSlice";
import questionHistorySlice from "store/slices/questionHistorySlice";
import cardsSlice from "store/slices/cardsSlice";
import reportCheckedSlice from "store/slices/reportCheckedSlice";
import roomInfoSlice from "./slices/roomInfoSlice";
import userInfoSlice from "./slices/userInfoSlice";
import leaderboardSlice from "./slices/leaderboardSlice";
import gainedExpSlice from "store/slices/gainedExpSlice";
import currencySlice from "store/slices/currencySlice";
import inventorySlice from "store/slices/inventorySlice";
import shopSlice from "store/slices/shopSlice";

export const store = configureStore({
  reducer: {
    authForm: authFormSlice,
    currentlySending: sendingRequestSlice,
    isLoading: loadingSlice,
    errorMessage: errorMessageSlice,
    data: dataSlice,
    freshLogin: freshLoginSlice,
    onlineCount: onlineCountSlice,
    progress: progressSlice,
    completed: completedSlice,
    gameSession: sessionSlice,
    gameDifficulty: difficultySlice,
    instructionsShown: instructionShownSlice,
    leaderboard: combineReducers({
      leaderboard: leaderboardSlice,
    }),
    currency: currencySlice,
    inventory: inventorySlice,
    shop: shopSlice,
    room: roomInfoSlice,
    me: meSlice,
    game: combineReducers({
      myTurn: myTurnSlice,
      role: roleSlice,
      cards: cardsSlice,
      itCard: itCardSlice,
      opponentItCard: opponentItCardSlice,
      me: meSlice,
      opponent: opponentSlice,
      relations: relationSlice,
      target: combineReducers({
        index: targetRelationSlice,
        value: targetInputSlice,
      }),
      action: askerActionSlice,
      guess: guessCardSlice,
      stage: stageSlice,
      question: questionSlice,
      answer: answerSlice,
      questionHistory: questionHistorySlice,
      reportChecked: reportCheckedSlice,
      textMode: textModeSlice,
      forfeited: forfeitedSlice,
      gameover: gameoverSlice,
      gainedExp: gainedExpSlice,
    }),
    userInfo: userInfoSlice,
  },
  devTools: true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
