import RegisterForm from 'components/Register/RegisterForm'
import { Link, Navigate } from 'react-router-dom'
import { useAuth } from 'utils/auth'


export default function RegisterContainer() {
  const [logged] = useAuth()

  return (
    <div>
      {logged ? (
        <Navigate to="/" />
      ) : (
        <div className="AuthWrapper">
          <div className="AuthWrapper_content">
            <div className="AuthCard">
              <div className="text-center AuthCard_body">
                <img src="public/logo-big.png" alt="logo" className="mb-6 AuthCard_body_logo" />
                <h1 className="mb-6 text-xlarge">Register</h1>
                <span className="text-important">Please remember your login info!</span>
                <br />
                <span className="text-muted text-small">You won't be able to reset nor retrieve the password.</span>
                <br />
                <br />
                <RegisterForm />
                <br />
                <p>
                  Already have an account? <Link to="/login" className="underline underline-offset-1 text-redFlame">Login</Link>
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
