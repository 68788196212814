import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { PlayerInfo } from "store/slices/roomInfoSlice";
import { useWebSocket } from "utils/WebSocketProvider";
import PlayerData from "./PlayerData";

const LobbyContainer = () => {
  const me = useAppSelector((state) => state.game.me);
  const socket = useWebSocket();
  const room = useAppSelector((state) => state.room);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("trying to join");

    if (socket) {
      // if (room.roomId === '' || room.players.length >= 2) {
      //   toast.error("The room is full or doesn't exist, please try joining another room");
      //   navigate('/game_selection')
      // }

      socket?.emit(
        "room_info",
        {},
        (already_running: boolean) => {
          if (already_running) {
            navigate("/play");
          }
        },
        []
      );
    }
  }, [socket]);

  const redirectToGameSelection = () => {
    socket?.emit("leave");
    navigate("/game_selection");
  };

  const handleStartPressed = () => {
    socket?.emit("ready");
  };

  const getReadyState = (players: PlayerInfo[]) => {
    const x = players.findIndex((it) => it.playerId == me);
    if (x >= 0) {
      return players[x].isReady;
    }
    return false;
  };

  return (
    <div className="pt-4 pr-4 pb-2 pl-4 flex flex-col h-[100vh] text-center">
      <Grid
        container
        spacing={2}
        columns={{ xs: 3, sm: 5, md: 12 }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="min-w-full pt-20 pb-2 pl-4 pr-4"
      >
        <Grid item xs={12} className="text-2xl">
          <Typography className="text-center" variant="h5">
            Room {room.roomId}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={5} md={6}>
          {room.players.length >= 1 ? (
            <PlayerData
              displayName={room.players[0].playerId}
              ready={room.players[0].isReady}
            />
          ) : (
            <PlayerData displayName="Waiting..." ready={false} />
          )}
        </Grid>
        <Grid item xs={3} sm={5} md={6}>
          {room.players.length >= 2 ? (
            <PlayerData
              displayName={room.players[1].playerId}
              ready={room.players[1].isReady}
            />
          ) : (
            <PlayerData displayName="Waiting..." ready={false} />
          )}
        </Grid>
        <Grid item xs={12} style={{ flexGrow: "1" }}></Grid>
        <Grid item xs={12}>
          <Button
            variant="text"
            className={`w-40 ReadyButton ${
              getReadyState(room.players) ? "!bg-danger" : "!bg-success"
            }`}
            size="medium"
            onClick={handleStartPressed}
            disableElevation
          >
            {getReadyState(room.players) ? "Unready" : "Ready"}
          </Button>
          <p className="pt-2 text-center text-small">
            The game will start automatically when <br /> both players are
            ready.
          </p>
          <div className="py-4"></div>
          <a
            className="w-40 mb-8 text-center AuthButton btn btn-primary text-small"
            style={{ padding: "0.8em" }}
            onClick={redirectToGameSelection}
          >
            Back
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default LobbyContainer;
