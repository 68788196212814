import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface ItCard {
  id: string
  image_url: string
  definitions?: string[]
}

const initialState: ItCard = {
  id: '',
  image_url: ''
}

const itCardSlice = createSlice({
  name: 'itCard',
  initialState,
  reducers: {
    setItCard: (state, action: PayloadAction<ItCard>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setItCard } = itCardSlice.actions

export default itCardSlice.reducer