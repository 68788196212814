import PlayContainer from "components/Game/PlayContainer";
import HomeContainer from "components/Home/HomeContainer";
import Instructions from "components/Instructions/Instructions";
import GameSelection from "components/GameSelection/GameSelection";
import Leaderboard from "components/Leaderboard/Leaderboard";
import LobbyContainer from "components/Lobby/LobbyContainer";
import LoginContainer from "components/Login/LoginContainer";
import LogoutContainer from "components/Logout/LogoutContainer";
import ProlificEntry from "components/Prolific/ProlificEntry";
import ExitQuestionnaire from "components/Questionnaire/ExitQuestionnaire";
import Questionnaire from "components/Questionnaire/Questionnaire";
import RegisterContainer from "components/Register/RegisterContainer";
import { Toaster } from "react-hot-toast";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useAuth } from "utils/auth";
import WebSocketProvider from "utils/WebSocketProvider";
import React from "react";
import InviteScreen from "components/Lobby/InviteScreen";
import UserProfile from "components/UserProfile/UserProfile";
import Store from "components/Store/Store";

const PrivateRoute = () => {
  const [logged] = useAuth();
  let location = useLocation();

  return logged ? (
    <WebSocketProvider>
      <Outlet />
    </WebSocketProvider>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export const MyRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginContainer />} />
        <Route path="/register" element={<RegisterContainer />} />
        <Route path="/logout" element={<LogoutContainer />} />
        <Route path="/prolific" element={<ProlificEntry />} />

        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/"
            element={
              <WebSocketProvider>
                <HomeContainer />
              </WebSocketProvider>
            }
          />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route
            path="/game_selection"
            element={
              <WebSocketProvider>
                <GameSelection />
              </WebSocketProvider>
            }
          />
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/store" element={<Store />} />
          <Route path="/howto" element={<Instructions />} />
          <Route path="/questionnaire" element={<Questionnaire />} />
          <Route
            path="/play"
            element={
              <WebSocketProvider>
                <PlayContainer />
              </WebSocketProvider>
            }
          />
          <Route
            path="/join_room/room-:roomId"
            element={
              <WebSocketProvider>
                <InviteScreen />
              </WebSocketProvider>
            }
          />
          {/*<Navigate replace to="/room-:roomId" />*/}
          <Route
            path="/room-:roomId"
            element={
              <WebSocketProvider>
                <LobbyContainer />
              </WebSocketProvider>
            }
          />
          <Route path="/exit_questionnaire" element={<ExitQuestionnaire />} />
        </Route>

        {/* <Route path='*' element={<Navigate to="/" />}/> */}
      </Routes>
      <Toaster
        toastOptions={{
          duration: 5000,
        }}
      />
    </BrowserRouter>
  );
};
