import Card from 'components/Game/Card/Card'
import React from 'react'
import { Card as CardObject } from 'store/slices/cardsSlice'

const Board = ({ objects }: { objects: CardObject[] }) => {

  return (
    <div className="CardBoard_Wrapper">
      <div className="CardBoard">
        {objects.map((object, index) =>
          <Card index={index} imageLink={object.image_url} key={object.id} name={object.id} definitions={object.definitions} showName />
        )
        }
      </div>
    </div>
  )
}

export default React.memo(Board)