import LoginForm from 'components/Login/LoginForm'
import { useEffect } from 'react'
import { Link, Navigate, useLocation } from 'react-router-dom'
import { useAppDispatch } from 'store/hooks'
import { setErrorMessage } from 'store/slices/errorMessageSlice'
import { setFreshLogin } from 'store/slices/freshLoginSlice'

import { useAuth } from 'utils/auth'

interface LoginLocationState { from: { pathname: string } | null }

export default function LoginContainer() {
  const [logged] = useAuth()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const locationState = (location.state as LoginLocationState) || { from: { pathname: "/" } }
  const redirectPath = locationState.from?.pathname

  useEffect(() => {
    dispatch(setErrorMessage(''))
  }, [])

  const goToHome = () => {
    dispatch(setFreshLogin(true))
    return <Navigate to={`${redirectPath}`} />
  }

  return (
    <div>
      {logged ? (
        goToHome()
      ) : (
        <div className="AuthWrapper">
          <div className="AuthWrapper_content">
            <div className="AuthCard">
              <div className="text-center AuthCard_body">
                <img src="public/logo-big.png" alt="logo" className="mb-6 AuthCard_body_logo" />
                <h1 className="mb-6 text-xlarge">Login</h1>
                <span className="text-muted">Insert your credentials</span>
                <br />
                <br />
                <LoginForm />
                <br />
                <p>
                  Don't have an account? <Link to="/register" className="underline underline-offset-1 text-redFlame">Signup</Link>
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
