import React, { ReactNode, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-st-modal";
import io, { Socket } from "socket.io-client";
import { useAppDispatch } from "store/hooks";
import { setAnswer } from "store/slices/answerSlice";
import { Card, setCards } from "store/slices/cardsSlice";
import { setForfeited } from "store/slices/forfeitedSlice";
import { setGameover } from "store/slices/gameoverSlice";
import { ItCard, setItCard } from "store/slices/itCardSlice";
import { setMe } from "store/slices/meSlice";
import { setMyTurn } from "store/slices/myTurnSlice";
import { setOpponentItCard } from "store/slices/opponentItCardSlice";
import { setOpponent } from "store/slices/opponentSlice";
import {
  Question,
  setQuestionHistory,
} from "store/slices/questionHistorySlice";
import { setQuestion } from "store/slices/questionSlice";
import { Relation, setRelations } from "store/slices/relationSlice";
import { setRole } from "store/slices/roleSlice";
import { RoomInfo, setRoomInfo } from "store/slices/roomInfoSlice";
import { setSession } from "store/slices/sessionSlice";
import { setStage } from "store/slices/stageSlice";
import { setGainedExp } from "store/slices/gainedExpSlice";
import bellsSound from "../audio/bells.mp3";
import failSound from "../audio/fail.mp3";
import successSound from "../audio/success.mp3";
import { SERVER_URL } from "../constants/AppConstants";
import { getAccessToken } from "./auth";
import useLegacySound from "./useLegacySound";

interface ServerToClientEvents {
  noArg: () => void;
  basicEmit: (a: number, b: string, c: Buffer) => void;
  withAck: (d: string, callback: (e: number) => void) => void;
  session: (game_session: string) => void;
  turn: (isMyTurn: boolean) => void;
  role: (myRole: string) => void;
  cards: (cards: Card[]) => void;
  itCard: (itCard: ItCard) => void;
  opponentItCard: (itCard: ItCard) => void;
  me: (me: string) => void;
  opponent: (opponent: string) => void;
  start: () => void;
  relations: (relations: Relation[]) => void;
  stage: (stage: string) => void;
  question: (question: string) => void;
  answer: (answer: string) => void;
  questionHistory: (history: Question[]) => void;
  gameover: (forfeited: boolean) => void;
  won: (won: boolean) => void;
  leave: (player: string) => void;
  join: (player: string) => void;
  retry: () => void;
  forfeit: () => void;
  bonus: () => void;
  connect_failed: (err: Error) => void;
  error: (err: Error) => void;
  used_card_flip: (ack: boolean) => void;
}

interface ClientToServerEvents {
  join: () => void;
  leave: () => void;
  forfeit_game: () => void;
  in_active_game: () => void;
  get_whole_game: () => void;
  send_question: () => void;
  end_turn: () => void;
  make_guess: () => void;
  reply_question: () => void;
  flip_card_hint: () => void;
}

// let socket: Socket<ServerToClientEvents, ClientToServerEvents> | null

export const WebSocketContext = React.createContext<Socket<
  ServerToClientEvents,
  ClientToServerEvents
> | null>(null);

const WebSocketProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const playWon = useLegacySound(successSound, 0.5);
  const playLose = useLegacySound(failSound, 0.35);
  const playTurn = useLegacySound(bellsSound, 0.5);
  const [connection, setConnection] = useState<Socket | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const socket = io(SERVER_URL, {
      // withCredentials: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 2000,
      reconnectionDelayMax: 5000,
      autoConnect: false,
      transports: ["polling", "websocket"],
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `${
              getAccessToken() ? `Bearer ${getAccessToken()}` : ""
            }`,
          },
        },
      },
    });

    console.log("loading socket once");

    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    socket.on("room", (roomInfo: RoomInfo) => {
      dispatch(setRoomInfo(roomInfo));
      console.log(`Joined room ${roomInfo}!`);
      console.log("room:", roomInfo);
      navigate(`/room-${roomInfo.roomId}`, { replace: true });
    });

    socket.on("ready", () => {
      console.log(`Player is ready`);
    });

    socket.on("unready", () => {
      console.log(`Player is not ready`);
    });

    socket.on("session", (game_session) => {
      console.log(`Joined game session ${game_session}`);
      dispatch(setSession(game_session));
    });

    socket.on("turn", (isMyTurn) => {
      console.log(isMyTurn ? "It is my turn now" : "It is the opponents turn");
      dispatch(setMyTurn(isMyTurn));
      if (isMyTurn) {
        toast.success("It is your turn now!", { id: "turn", duration: 5000 });
        playTurn();
      }
    });

    socket.on("role", (myRole) => {
      console.log(`I am the ${myRole}`);
      dispatch(setRole(myRole));
    });

    socket.on("cards", (cards) => {
      console.log("Received set of cards", cards);
      dispatch(setCards(cards));
    });

    socket.on("itCard", (itCard) => {
      console.log("Received my IT card", itCard);
      dispatch(setItCard(itCard));
    });

    socket.on("opponentItCard", (itCard) => {
      console.log("Received opponent's IT card", itCard);
      dispatch(setOpponentItCard(itCard));
    });

    socket.on("me", (me) => {
      console.log(`I am ${me}`);
      dispatch(setMe(me));
    });

    socket.on("opponent", (opponent) => {
      console.log(`Opponent is ${opponent}`);
      dispatch(setOpponent(opponent));
    });

    socket.on("start", () => {
      console.log("Starting game!");
      navigate("/play");
      // fixes bug where game would be stuck at end screen
      dispatch(setGameover(false));
    });

    socket.on("relations", (relations) => {
      console.log("Receiving relations!", relations);
      dispatch(setRelations(relations));
    });

    socket.on("stage", (stage) => {
      console.log(`Current stage is ${stage}`);
      dispatch(setStage(stage));
    });

    socket.on("question", (question) => {
      console.log(`Current question is ${question}`);
      dispatch(setQuestion(question));
    });

    socket.on("answer", (answer) => {
      console.log(`The answer was ${answer}`);
      dispatch(setAnswer(answer));
    });

    socket.on("questionHistory", (history) => {
      console.log(`Updated question history ${history}`);
      dispatch(setQuestionHistory(history));
    });

    socket.on("gameover", (forfeited) => {
      console.log(`The game ended`);
      dispatch(setForfeited(forfeited));
      dispatch(setGameover(true));
    });

    socket.on("won", (won) => {
      console.log(`You won = ${won}`);
      dispatch(setGainedExp(won));
      if (won) {
        playWon();
      } else {
        playLose();
      }
    });

    socket.on("leave", (player) => {
      toast.dismiss("success");
      toast.error(
        `The opponent '${player}' left the game!\nIf the player does not rejoin, you can forfeit the game from the menu to forcefully end this game session.`,
        { id: "error", duration: 10000 }
      );
      console.log(`${player} left the game!`);
    });

    socket.on("join", (player) => {
      toast.dismiss("error");
      toast.success(`The opponent '${player}' joined the game!`, {
        id: "success",
        duration: 5000,
      });
      console.log(`${player} joined the game!`);
    });

    socket.on("retry", () => {
      toast(
        `Your opponent replied UNCLEAR. Try rewording or changing the question.`,
        { id: "info", duration: 10000, icon: "❗" }
      );
      console.log(`Retrying round`);
    });

    socket.on("forfeit", () => {
      toast(`Your opponent forfeited the game.`, {
        id: "info",
        duration: 5000,
        icon: "❗",
      });
      console.log(`Opponent forfeited`);
    });

    socket.on("bonus", () => {
      // toast(`Your opponent guessed your card correctly! You can still take a guess of the opponents card!`, { id: ' info', duration: 10000, icon: '❗' })
      Alert(
        <p>
          Your opponent guessed your card correctly! <br /> You can still take a
          guess of the opponents card.
        </p>,
        "Bonus guess"
      );
      console.log(`Opponent won. Playing bonus round.`);
    });

    // CONNECTION ERRORS

    socket.on("connect_error", (err) => {
      toast.error(
        "Encountered an error when connecting to server. Please report this to the developers!",
        { id: "connection", duration: 5000 }
      );
    });

    socket.on("connect_failed", (err) => {
      toast.error(
        "Failed to connect to server. Please report this to the developers!",
        { id: "connection", duration: 5000 }
      );
    });

    socket.on("error", (err) => {
      console.log(err);
      toast.error("Error happened...", { id: "error", duration: 5000 });
    });

    socket.connect();

    setConnection(socket);
  }, []);

  return (
    <WebSocketContext.Provider value={connection}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = (): Socket | null => {
  const ctx = useContext(WebSocketContext);
  if (ctx === undefined) {
    throw new Error("useWebSocket can only be used inside WebSocketContext");
  }
  return ctx;
};

export default WebSocketProvider;
