import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export enum Difficulty {
  EASY,
  MEDIUM,
  HARD
}

const initialState = Difficulty.EASY

const difficultySlice = createSlice({
  name: 'difficulty',
  initialState,
  reducers: {
    setDifficulty: (state, action: PayloadAction<Difficulty>) => {
      return state = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setDifficulty } = difficultySlice.actions

export default difficultySlice.reducer