import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useCopyToClipboard } from "usehooks-ts";
import { useWebSocket } from "utils/WebSocketProvider";
import Tooltip from "@mui/material/Tooltip";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { getAccessToken } from "utils/auth";
import api from "utils/api";
import toast from "react-hot-toast";
import { sendingRequest } from "store/slices/sendingRequestSlice";
import { setErrorMessage } from "store/slices/errorMessageSlice";
import { Dispatch } from "@reduxjs/toolkit";
import { setCurrency } from "store/slices/currencySlice";
import { InventoryInfo, setInventory } from "store/slices/inventorySlice";
import { HintType, setHintPrices, ShopInfo } from "store/slices/shopSlice";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export const getCurrency = () => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true));
    dispatch(setErrorMessage(""));
    api
      .get("/api/currency", {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((data) => {
        dispatch(setCurrency(data.data as number));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText));
        }
      })
      .finally(() => {
        dispatch(sendingRequest(false));
      });
  };
};

export const getInventory = () => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true));
    dispatch(setErrorMessage(""));
    api
      .get("/api/me/inventory", {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((data) => {
        dispatch(setInventory(data.data as InventoryInfo));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText));
        }
      })
      .finally(() => {
        dispatch(sendingRequest(false));
      });
  };
};

export const getHintPrices = () => {
  return (dispatch: Dispatch) => {
    dispatch(sendingRequest(true));
    dispatch(setErrorMessage(""));
    api
      .get("/api/shop/hints", {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      })
      .then((data) => {
        dispatch(setHintPrices(data.data as ShopInfo));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(setErrorMessage(error.response.statusText));
        }
      })
      .finally(() => {
        dispatch(sendingRequest(false));
      });
  };
};

const PlayerData = ({
  displayName,
  ready,
}: {
  displayName: string;
  ready: boolean;
}) => {
  const me = useAppSelector((state) => state.game.me);
  // used when adding items to the cart
  const [freeTextCount, setFreeTextCount] = React.useState(0);
  const [cardFlipCount, setCardFlipCount] = React.useState(0);

  const [inviteUrl, copyInviteUrl] = useCopyToClipboard();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const location = useLocation();

  const currency = useAppSelector((state) => state.currency);
  const inventory = useAppSelector((state) => state.inventory);
  const hintPrices = useAppSelector((state) => state.shop);

  const [cardFlipQuantity, setCardFlipQuantity] = useState(0);
  const [freeTextQuantity, setFreeTextQuantity] = useState(0);

  const [openFreeTextDialog, setOpenFreeTextDialog] = React.useState(false);
  const [openCardFlipDialog, setOpenCardFlipDialog] = React.useState(false);

  const handleFreeTextDialogOpen = () => {
    setFreeTextCount(0);
    setOpenFreeTextDialog(true);
  };

  const handleFreeTextDialogClose = () => {
    setOpenFreeTextDialog(false);
  };

  const handleCardFlipDialogOpen = () => {
    setCardFlipCount(0);
    setOpenCardFlipDialog(true);
  };

  const handleCardFlipDialogClose = () => {
    setOpenCardFlipDialog(false);
  };

  const canAddFreeText =
    (freeTextCount + 1) * hintPrices.freeTextHint <= currency;

  const canSubtractFreeText = freeTextCount > 0;

  const canAddCardFlip =
    (cardFlipCount + 1) * hintPrices.flipCardHint <= currency;

  const canSubtractCardFlip = cardFlipCount > 0;

  const buyHints = (
    hintType: HintType,
    amount: number,
    closeDialogCallback: () => void
  ) => {
    if (currency === 0 || currency < hintPrices[hintType] * amount) {
      toast.error("You don't have enough currency to purchase this.", {
        id: "connection",
        duration: 5000,
      });
    } else {
      api
        .post(
          `/api/shop/hints/buy`,
          {
            type: hintType,
            amount: amount,
          },
          {
            headers: {
              Authorization: `Bearer ${getAccessToken()}`,
            },
          }
        )
        .then(() => {
          dispatch(getCurrency());
          dispatch(getInventory());
        })
        .catch((error: any) => {
          toast.error(error.message, { id: "connection", duration: 5000 });
        });

      closeDialogCallback();
    }
  };

  const rootUrl = window.location.host;

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(
    (state) => state.leaderboard.leaderboard.me
  );

  const generateInviteUrl = () => {
    setTooltipOpen(true);
    copyInviteUrl(`${rootUrl}/join_room${location.pathname}`);
  };

  useEffect(() => {
    setInterval(() => {
      setTooltipOpen(false);
    }, 3000);
  }, [tooltipOpen]);

  useEffect(() => {
    dispatch(getCurrency());
    dispatch(getInventory());
    dispatch(getHintPrices());
  }, []);

  useEffect(() => {
    setCardFlipQuantity(inventory["flipCardHint"] ?? 0);
    setFreeTextQuantity(inventory["freeTextHint"] ?? 0);
  }, [inventory]);

  return (
    <div className="PlayerInfo">
      {displayName === "Waiting..." ? (
        <>
          <img
            className="w-40"
            src="public/user.svg"
            alt="UserAvatar. Icon made by www.flaticon.com"
          />
          <div className="h-6" />
          <span className="w-40 text-2xl">Waiting...</span>
          <br />
          <span className="w-40 text-2xl"></span>
          <br />
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied link!"
            arrow
          >
            <Button
              variant="text"
              className="w-40 MenuButton"
              size="medium"
              onClick={generateInviteUrl}
              disableElevation
            >
              <ContentPasteIcon />
              Invite player
            </Button>
          </Tooltip>
        </>
      ) : (
        <>
          <img
            className="w-40"
            src="public/user.svg"
            alt="UserAvatar. Icon made by www.flaticon.com"
          />
          <div className="h-6" />
          <span className="w-40 text-2xl gap-x-1">{displayName}</span>
          <br />
          <span className="w-40 align-left">
            <b>Lvl.</b> {currentUser.level}
          </span>
          {me === displayName ? (
            <div className="flex flex-col">
              <Typography className="w-40">
                <b className="float-left">Currency:</b>
                <span className="float-right">${currency}</span>
              </Typography>
              <Typography className="w-40">
                <b className="float-left">Free text:</b>
                <span className="float-right">
                  x{freeTextQuantity + " "}
                  <AddCircleIcon
                    className="hover:cursor-pointer"
                    sx={{ marginBottom: 0.5 }}
                    onClick={handleFreeTextDialogOpen}
                  />
                </span>
              </Typography>
              <Typography className="w-40">
                <b className="float-left">Card flip:</b>
                <span className="float-right">
                  x{cardFlipQuantity + " "}
                  <AddCircleIcon
                    className="hover:cursor-pointer"
                    sx={{ marginBottom: 0.5 }}
                    onClick={handleCardFlipDialogOpen}
                  />
                </span>
              </Typography>
              <br />
            </div>
          ) : (
            <>
              <br />
              <br />
              <br />
              <br />
            </>
          )}

          <span className="w-40 text-2xl">{ready ? "Ready" : "Not ready"}</span>
        </>
      )}
      <Dialog open={openFreeTextDialog}>
        <DialogTitle>Buy free text hints</DialogTitle>
        <DialogContent className="flex flex-col gap-2">
          <Typography>
            <b>Inventory:</b>
            <span className="float-right">x{freeTextQuantity}</span>
          </Typography>
          <Typography>
            <b>Currency:</b>
            <span className="float-right">${currency}</span>
          </Typography>
          <Typography className="flex flex-row justify-between items-center">
            <Fab
              color="error"
              size="small"
              disabled={!canSubtractFreeText}
              onClick={() =>
                canSubtractFreeText && setFreeTextCount((count) => count - 1)
              }
              className="mr-2 ml-2"
            >
              <RemoveIcon />
            </Fab>
            x{freeTextCount}
            <Fab
              color="success"
              size="small"
              disabled={!canAddFreeText}
              onClick={() =>
                canAddFreeText && setFreeTextCount((count) => count + 1)
              }
              className="mr-2 ml-2"
            >
              <AddIcon />
            </Fab>
          </Typography>
          <Divider />
          <Typography>
            <b>Your total:</b>
            <span className="float-right	">
              ${freeTextCount * hintPrices.freeTextHint}
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFreeTextDialogClose}>Back</Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            form="difficultyForm"
            id="difficultySubmit"
            disabled={freeTextCount === 0}
            disableElevation
            onClick={() =>
              buyHints("freeTextHint", freeTextCount, handleFreeTextDialogClose)
            }
          >
            Buy hints
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openCardFlipDialog}>
        <DialogTitle>Buy card flip hints</DialogTitle>
        <DialogContent className="flex flex-col gap-2">
          <Typography>
            <b>Inventory:</b>
            <span className="float-right">x{cardFlipQuantity}</span>
          </Typography>
          <Typography>
            <b>Currency:</b>
            <span className="float-right">${currency}</span>
          </Typography>
          <Typography className="flex flex-row justify-between items-center">
            <Fab
              color="error"
              size="small"
              disabled={!canSubtractCardFlip}
              onClick={() =>
                canSubtractCardFlip && setCardFlipCount((count) => count - 1)
              }
            >
              <RemoveIcon />
            </Fab>
            x{cardFlipCount}
            <Fab
              color="success"
              size="small"
              disabled={!canAddCardFlip}
              onClick={() =>
                canAddCardFlip && setCardFlipCount((count) => count + 1)
              }
              className="mr-2 ml-2 bg-success"
            >
              <AddIcon />
            </Fab>
          </Typography>
          <Divider />
          <Typography>
            <b>Your total:</b>
            <span className="float-right">
              ${cardFlipCount * hintPrices.flipCardHint}
            </span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCardFlipDialogClose}>Back</Button>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            form="difficultyForm"
            id="difficultySubmit"
            disabled={cardFlipCount === 0}
            disableElevation
            onClick={() =>
              buyHints("flipCardHint", cardFlipCount, handleCardFlipDialogClose)
            }
            style={{ gridArea: "confirm", width: "50%" }}
          >
            Buy hints
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PlayerData;
